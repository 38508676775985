<template>
  <div class="mx-3">
    <h2 class="px-5 py-5">{{ $t('Ledger Report') }}</h2>
    <v-row class="px-10 py-1">
      <v-col md="3">
        <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('account name')" :items="accountItems" :loading="accountLoading" color="#757575"
          item-text="name" item-value="id" v-model="filters.account_id" @keypress="fetchAutoCompleteAccount($event)" @input="setFelterId">
        </v-autocomplete>
      </v-col>
      <v-col md=3>
        <AutoCompleteField :label="$t('Sub Account')" endPoint="/accounting/sub-account/auto-complete" :filter="filterId"
        v-model="filters.sub_account_id"/>
      </v-col>
      <v-col md="3">
        <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('folder name')" :items="folderItems" :loading="folderLoading" color="#757575"
          item-text="name" item-value="id" v-model="filters.folder" @keypress="fetchAutoCompleteFolder($event)">
        </v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('cost center')" :items="costCenterItems" color="#757575" item-text="name"
          item-value="id" :loading="costCenterLoading" v-model="filters.cost_center"
          @keypress="fetchAutoCompleteCostCenter($event)">
        </v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date" v-model="filters.from">
        </v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date" v-model="filters.to">
        </v-text-field>
      </v-col>
      <v-col md="2">
        <v-autocomplete :dark="$store.state.isDarkMode" v-model="filters.term" :loading="termsLoading" :items="terms" :label="$t('term')"
          prepend-icon="mdi-counter" item-text="name" item-value="id" @keypress="fetchTerm($event)"
          color="#757575"></v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-checkbox :dark="$store.state.isDarkMode" :label="$t('Previous Balance')" class="mx-4 mb-4" v-model="filters.previous_balance"
          hide-details></v-checkbox>
      </v-col>
      <v-col md="2">
        <v-text-field :dark="$store.state.isDarkMode" :label="$t('debtor')" type="number" v-model="filters.debit">
        </v-text-field>
      </v-col>
      <v-col md="2">
        <v-text-field :dark="$store.state.isDarkMode" :label="$t('credit')" type="number" v-model="filters.credit">
        </v-text-field>
      </v-col>
      <v-col md="8">

      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="blue" dark block @click="exportToExcel(filters)">{{ $t('hr.time.export') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-simple-table :dark="$store.state.isDarkMode">
      <thead class="table-heading" v-if="!loading">
        <tr>
          <th v-for="(name, i) in tableHeaders" :key="i">
            {{ $t(name) }}
          </th>
        </tr>
      </thead>
      <div class="table__spinnerWrapper" v-if="loading">
        <app-spinner></app-spinner>
      </div>
      <v-fade-transition mode="out-in" :group="true" tag="tbody" v-if="!loading">
        <template>
          <tr v-for="item in tableItems" :key="item.id">
            <td v-if="item.type == 'debit'">
              {{ item.amount.toLocaleString() }}
            </td>
            <td v-if="item.type == 'debit'"></td>
            <td v-if="item.type == 'credit'"></td>
            <td v-if="item.type == 'credit'">
              {{ item.amount.toLocaleString() }}
            </td>
            <td>
              {{ item.entry.source }}
            </td>
            <td>{{ item.status }}</td>
            <td>
              <span v-if="item.desc">{{ item.desc }}</span>
            </td>
            <td>
              <span v-if="item.sub_account">{{ item.sub_account.name }}</span>
            </td>
            <td>
              <span v-if="item.cost_center">{{ item.cost_center.name }}</span>
            </td>
            <td>{{ item.date }}</td>
            <td>
              <v-icon v-if="returnAbility('entry:show') && (!item.entry.operate) && (item.entry.owner_type != null)"
                color="green"
                @click="goTodestination(item.entry.owner_type, item.entry.owner_id, item.entry.owner_type_id)">mdi-pencil</v-icon>
              <!-- <v-icon v-if="returnAbility('entry:update') && returnAbility('entry:show') && (item.entry.operate)" color="info" @click="$router.push(`/editAccountingEntry/${item.entry.id}`)">mdi-pencil</v-icon>
                <app-delete-account-entry v-if="returnAbility('entry:delete') && (item.entry.operate)" :tableRecord="item"
                  serverRoute="/accounting/entry" @record-deleted="fetchAccountingEntry"></app-delete-account-entry> -->
            </td>
          </tr>
        </template>
      </v-fade-transition>
    </v-simple-table>

    <div class="d-flex justify-end mt-5">
      <v-row>
        <v-col md="5 ">
          <v-switch @change="goToAll" v-model="is_all" color="green" :label="$t('Show All')"></v-switch>
        </v-col>
        <app-pagination v-if="(!loading & (paginated))" :disable-pagination="!paginated" :totalPages="total"
          :page="page" @PaginationValue="applyPagination($event)"></app-pagination>

      </v-row>
    </div>


    <div class="d-flex  account-entry__chip__wrapper">
      <div class="account-entry__chip">
        {{ $t('total debit') }}
        <span>{{ totalDebit }}</span>
      </div>
      <div class="account-entry__chip">
        {{ $t('total credit') }}
        <span>{{ totalCredit }}</span>
      </div>
      <div class="account-entry__chip">
        {{ $t('balance') }}
        <span>{{ remainingBalance }}</span>
      </div>
    </div>
    <div style="height: 10px;">
    </div>
  </div>
</template>

<script>
import axios from "axios";
import debounce from "../../../../helpers/debounce";
import pagination from "../../../../components/base/pagination.vue";
import spinner from "../../../../components/base/spinner.vue";
import AutoCompleteField from "../../components/core/AutoCompleteField.vue";
import { saveAs } from 'file-saver';
export default {
  components: {
    // appEditAccount: editAccount,
    appSpinner: spinner,
    appPagination: pagination,
    AutoCompleteField,
  },
  data: () => ({
    filterId: null,
    is_all: false,
    currentAccount: {},
    totalDebit: 0,
    totalCredit: 0,
    remainingBalance: 0,
    termsLoading: false,
    terms: [],
    costCenterItems: [],
    costCenterLoading: false,
    accountItems: [],
    accountLoading: false,
    folderItems: [],
    folderLoading: false,
    tableItems: [],
    paginated: true,
    loading: false,
    page: 1,
    total: 1,
    tableHeaders: [
      "debtor",
      "credit",
      "Document",
      "account name",
      "explain",
      "Sub Account",
      "cost center",
      "date",
      "settings",
    ],
    items: [],
    filters: {
      account_id: null,
      cost_center: null,
      folder: null,
      final_account: null,
      from: null,
      to: null,
      debit: null,
      credit: null,
      term: null,
      previous_balance: false,
      sub_account_id: null,
    },
  }),
  methods: {
    goToAll() {
      if (this.is_all) {
        this.paginated = false;
      } else { this.paginated = true; }
      this.getAll();
    },
    setFelterId() {
      this.filterId = this.filters.account_id;
    },
    goTodestination(dis, id, type) {
      if (dis == "VOUCHER") {
        this.$router.push(`/edit-document/${id}?document_id=${type}`);
      }
      // if (dis == "FINANCIALORDER") {
      //   this.$router.push(`/editAccountingEntry/${id}`);
      // }
      if (dis == "INVOICE") {
        this.$router.push(`/edit-invoice/${id}?invoice_id=${type}`);
      }
      if(dis == "ENTRY") {
        this.$router.push(`/editAccountingEntry/${id}`);
      }
    },
    applyPagination(paginationValue) {
      this.page = paginationValue;
      this.getAll();
    },
    async exportToExcel(queryParams) {
      try {
        const excel = await axios.get(`/accounting/account/statement-list/export`, {
          params: {
            ...queryParams,
          },
          responseType: 'blob'
        });
        saveAs(excel.data)
      } catch (err) {
        console.log('err', err);
      } finally {
      }
    },
    async fetchTerm() {
      try {
        this.termsLoading = true;
        const response = await axios.get("terms", {
          params: {
            size: 100000,
          },
        });
        this.terms = response.data.terms;
      } catch (err) {
      } finally {
        this.termsLoading = false;
      }
    },
    clearFilter() {
      this.filters.account_id = undefined;
      this.filters.folder = undefined;
      this.filters.cost_center = undefined;
      this.filters.from = undefined;
      this.filters.to = undefined;
      this.filters.term = undefined;
      this.filters.final_account = undefined;
      this.filters.previous_balance = undefined;
      this.filters.sub_account_id = undefined;
      this.page = 1;
      this.getAll();
    },

    async getAll() {
      try {
        const filterTemp = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) filterTemp[key] = this.filters[key];
        });
        this.loading = true;
        const res = await axios.get("/accounting/account/statement-list", {
          params: {
            size: 10,
            page: this.page,
            paginated: this.paginated,
            ...filterTemp,
          },
        });
        const arr = Array.from(res.data.data)
        if (Array.isArray(arr)) {
          console.log(arr);
          this.tableItems = arr.map((el) => {
            return {
              ...el,
            };
          });
        }
        this.total = res.data.total;
        this.page = res.data.current_page;
        this.totalDebit = res.data.amount.debit;
        this.totalCredit = res.data.amount.credit;
        this.remainingBalance = res.data.amount.balance;
      } finally {
        this.loading = false;
      }
    },
    async fetchAutoCompleteAccount(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.accountLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.accountItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.accountLoading = false;
        }
      }, 500)();
    },
    async fetchAutoCompleteFolder(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.folderLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/folder/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.folderItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.folderLoading = false;
        }
      }, 500)();
    },
    async fetchAutoCompleteCostCenter(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.costCenterLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/cost-center/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.costCenterItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.costCenterLoading = false;
        }
      }, 500)();
    },
  },
  created() {
    this.getAll();
  },
};
</script>

<style></style>